import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueApexCharts from "vue-apexcharts";
import { auth, onAuthStateChanged, GoogleAuthProvider } from "@/firebase/init";
import InstantSearch from "vue-instantsearch";
import VueConfetti from "vue-confetti";

// Components
import Loading from "@/components/Loading";
Vue.component("Loading", Loading);
import DelayedTooltip from "@/components/misc/ui/DelayedTooltip";
import GroupByHeaderRow from "@/components/misc/ui/GroupByHeaderRow";
import AccountViewerLabel from "@/components/misc/ui/AccountViewerLabel";
import Toast from "@/components/misc/ui/Toast";
import SalesOrderViewerLabel from "@/components/misc/ui/SalesOrderViewerLabel";
import RecurringDatePicker from "@/components/misc/ui/RecurringDatePicker";

Vue.component("DelayedTooltip", DelayedTooltip);
Vue.component("GroupByHeaderRow", GroupByHeaderRow);
Vue.component("AccountViewerLabel", AccountViewerLabel);
Vue.component("RecurringDatePicker", RecurringDatePicker);
Vue.component("Toast", Toast);
Vue.component("SalesOrderViewerLabel", SalesOrderViewerLabel);

// Libraries
Vue.use(require("vue-moment"));
Vue.use(VueApexCharts);
Vue.component("ApexChart", VueApexCharts);
Vue.use(InstantSearch);
Vue.use(VueConfetti);

// Utils
import CacheManager from "@/utils/CacheManager";
const cacheManager = new CacheManager("logoCache", 1800000); // 30-minute TTL
Vue.prototype.$cacheManager = cacheManager;

// Filters
import "@/utils/filters";

// Validation Mixin
Vue.mixin({
  methods: {
    cleanedPhoneNumber(value) {
      return String(value).replace(/[^0-9]/g, "");
    },
    isValidEmail(value) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) ? true : false;
    },
    isValidPhoneNumber(value) {
      const pattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      return pattern.test(value) ? true : false;
    },
    //  Dynamic text color based on background color (https://wunnle.com/dynamic-text-color-based-on-background)
    //  - Allows us to increase legibility and accessibility when using dark background colors in tables, cards, etc.
    getRGB(color) {
      return parseInt(color, 16) || color;
    },
    getsRGB(color) {
      return this.getRGB(color) / 255 <= 0.03928
        ? this.getRGB(color) / 255 / 12.92
        : Math.pow((this.getRGB(color) / 255 + 0.055) / 1.055, 2.4);
    },
    getLuminance(hexColor) {
      return (
        0.2126 * this.getsRGB(hexColor.substr(1, 2)) +
        0.7152 * this.getsRGB(hexColor.substr(3, 2)) +
        0.0722 * this.getsRGB(hexColor.substr(-2))
      );
    },
    getContrast(foreground, background) {
      const luminance1 = this.getLuminance(foreground);
      const luminance2 = this.getLuminance(background);
      return (
        (Math.max(luminance1, luminance2) + 0.05) /
        (Math.min(luminance1, luminance2) + 0.05)
      );
    },
    getTextColor(bgColor) {
      const whiteContrast = this.getContrast(bgColor, "#ffffff");
      const blackContrast = this.getContrast(bgColor, "#000000");

      return whiteContrast > blackContrast ? "#ffffff" : "#000000";
    },
    async delay(ms) {
      return new Promise((res) => setTimeout(res, ms));
    },
  },
  computed: {
    isMobileDevice() {
      return vuetify.framework.breakpoint.smAndDown;
    },
    isLargerMobileDevice() {
      return vuetify.framework.breakpoint.mdAndDown;
    },
  },
});

Vue.config.productionTip = false;

let app = null;
onAuthStateChanged(auth, async (user) => {
  if (user) {
    const { claims } = await auth.currentUser.getIdTokenResult();
    store.commit("setUser", {
      uid: user.uid,
      accessToken: user.accessToken,
      providers: user.providerData,
      name: {
        first: user.displayName.split(" ")[0].toLowerCase(),
        last: user.displayName.split(" ")[1].toLowerCase(),
      },
      initials: user.displayName
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toLowerCase(),
      phoneNumber: user.phoneNumber || "",
      email: user.email,
      brands: claims.brands,
      accountType: claims.accountType,
      country: claims.country,
      state: claims.state,
      role: claims.role,
      dashboards: claims.dashboards ? claims.dashboards : [],
      views: claims.views ? claims.views : [],
    });
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    });
  }
  app.$mount("#app");
});
