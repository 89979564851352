import trelloService from "@/services/trello";

const state = {
  board: null,
  lists: [],
  cards: {},
  members: [],
  loading: false,
  error: null,
  lastSyncTime: null,
  syncInterval: null,
  activityPollingInterval: null,
};

const mutations = {
  SET_BOARD(state, board) {
    state.board = board;
  },
  SET_LISTS(state, lists) {
    state.lists = lists;
  },
  SET_CARDS(state, { listId, cards }) {
    state.cards = {
      ...state.cards,
      [listId]: cards,
    };
  },
  SET_MEMBERS(state, members) {
    state.members = members;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_LAST_SYNC_TIME(state, time) {
    state.lastSyncTime = time;
  },
  SET_SYNC_INTERVAL(state, interval) {
    state.syncInterval = interval;
  },
  SET_ACTIVITY_POLLING_INTERVAL(state, interval) {
    state.activityPollingInterval = interval;
  },
};

const TRELLO_COLORS = {
  green: "#519839",
  yellow: "#d9b51c",
  orange: "#cd8313",
  red: "#b04632",
  purple: "#89609e",
  blue: "#0079bf",
  sky: "#00c2e0",
  lime: "#4bbf6b",
  pink: "#ff78cb",
  black: "#4d4d4d",
};

const actions = {
  async fetchBoard({ commit }, boardId) {
    try {
      commit("SET_LOADING", true);
      const board = await trelloService.getBoard(boardId);
      commit("SET_BOARD", board);
    } catch (error) {
      console.error("Error fetching board:", error);
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async fetchLists({ commit }, boardId) {
    try {
      commit("SET_LOADING", true);
      const lists = await trelloService.getLists(boardId);
      commit("SET_LISTS", lists);
    } catch (error) {
      console.error("Error fetching lists:", error);
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async fetchCards({ commit }, listId) {
    try {
      commit("SET_LOADING", true);
      const cards = await trelloService.getCards(listId);
      commit("SET_CARDS", { listId, cards });
      return cards; // Return the cards for verification
    } catch (error) {
      console.error("Error fetching cards:", error);
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async createCard({ commit }, { listId, cardData }) {
    try {
      commit("SET_LOADING", true);
      const response = await trelloService.createCard(listId, cardData);
      if (!response || !response.id) {
        throw new Error("Invalid response from Trello API");
      }
      return response; // Make sure we return the card data
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async attachFileToCard({ commit }, { cardId, formData }) {
    try {
      commit("SET_LOADING", true);
      await trelloService.attachFileToCard(cardId, formData);
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async updateCardList({ commit, dispatch }, { cardId, newListId }) {
    try {
      commit("SET_LOADING", true);

      // Get the card to find its current list before updating
      const card = await trelloService.getCard(cardId);
      const oldListId = card.idList;

      // Update the card's list
      await trelloService.updateCardList(cardId, newListId);

      // Refresh both old and new lists to ensure UI consistency
      if (oldListId) {
        await dispatch("fetchCards", oldListId);
      }
      await dispatch("fetchCards", newListId);
    } catch (error) {
      console.error("Error updating card list:", error);
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async updateCardPosition({ commit, dispatch }, { cardId, listId, position }) {
    try {
      commit("SET_LOADING", true);
      await trelloService.updateCardPosition(cardId, position);

      // Refresh the cards in the affected list
      await dispatch("fetchCards", listId);
    } catch (error) {
      console.error("Error updating card position:", error);
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async updateTrelloCard({ commit, dispatch }, { cardId, updateData }) {
    try {
      commit("SET_LOADING", true);
      const response = await trelloService.updateTrelloCard(cardId, updateData);

      // If we're changing lists, refresh the cards
      if (updateData.idList) {
        await dispatch("fetchCards", updateData.idList);
      }

      return response;
    } catch (error) {
      console.error("Error updating card:", error);
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async deleteTrelloCard({ commit, dispatch, state }, cardId) {
    try {
      commit("SET_LOADING", true);

      // Find the list ID before deleting the card
      let listId;
      for (const [key, cards] of Object.entries(state.cards)) {
        if (cards.find((card) => card.id === cardId)) {
          listId = key;
          break;
        }
      }

      await trelloService.deleteTrelloCard(cardId);

      // Refresh the cards in the affected list
      if (listId) {
        await dispatch("fetchCards", listId);
      }

      return true;
    } catch (error) {
      console.error("Error deleting card:", error);
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async deleteAttachment({ commit }, { cardId, attachmentId }) {
    try {
      commit("SET_LOADING", true);
      const response = await fetch(
        `https://api.trello.com/1/cards/${cardId}/attachments/${attachmentId}?key=${process.env.VUE_APP_TRELLO_API_KEY}&token=${process.env.VUE_APP_TRELLO_TOKEN}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to delete attachment: ${response.statusText}`);
      }

      return true;
    } catch (error) {
      console.error("Error deleting attachment:", error);
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async checkForUpdates({ dispatch, state, commit }) {
    try {
      if (!state.board?.id) {
        return;
      }

      const lists = await trelloService.getLists(state.board.id);

      const listsChanged = lists.some((newList) => {
        const existingList = state.lists.find((l) => l.id === newList.id);
        if (!existingList) return true;

        return (
          existingList.name !== newList.name ||
          existingList.closed !== newList.closed ||
          existingList.color !== newList.color
        );
      });

      const needsRefresh =
        listsChanged ||
        Object.keys(state.cards).length === 0 ||
        !state.lastSyncTime ||
        new Date() - new Date(state.lastSyncTime) > 15000;

      if (needsRefresh) {
        commit("SET_LISTS", lists);

        for (const list of lists) {
          try {
            const cards = await trelloService.getCards(list.id);
            commit("SET_CARDS", { listId: list.id, cards });
          } catch (error) {
            console.error(`Error fetching cards for list ${list.name}:`, error);
          }
        }

        commit("SET_LAST_SYNC_TIME", new Date().toISOString());
      }
    } catch (error) {
      console.error("Error checking for updates:", error);
      commit("SET_ERROR", error.message);
    }
  },

  async startHybridSync({ dispatch, commit, state }) {
    if (state.syncInterval) {
      clearInterval(state.syncInterval);
    }

    await dispatch("checkForUpdates");

    const syncInterval = setInterval(async () => {
      if (!state.loading) {
        try {
          await dispatch("checkForUpdates");
        } catch (error) {
          console.error("Background sync error:", error);
        }
      }
    }, 15000);

    commit("SET_SYNC_INTERVAL", syncInterval);
  },

  async handleCardAction({ dispatch }, { type, cardId, data }) {
    try {
      switch (type) {
        case "move":
          await dispatch("handleCardMove", data);
          break;
        case "update":
          await dispatch("handleCardUpdate", data);
          break;
        case "archive":
          await dispatch("handleCardArchive", data);
          break;
        case "delete":
          await dispatch("handleCardDelete", data);
          break;
        case "attachment":
          await dispatch("handleCardAttachment", data);
          break;
        case "comment":
          await dispatch("handleCardComment", data);
          break;
        default:
          console.warn("Unknown card action type:", type);
      }
    } catch (error) {
      console.error(`Error handling ${type} action:`, error);
      throw error;
    }
  },

  async handleCardMove(
    { dispatch },
    { cardId, oldListId, newListId, position }
  ) {
    await trelloService.updateTrelloCard(cardId, {
      idList: newListId,
      pos: position,
    });
    await dispatch("refreshAffectedLists", { oldListId, newListId });
  },

  async handleCardUpdate({ dispatch }, { cardId, listId, updateData }) {
    await trelloService.updateTrelloCard(cardId, updateData);
    await dispatch("fetchCards", listId);
  },

  async handleCardArchive({ dispatch }, { cardId, listId }) {
    await trelloService.updateTrelloCard(cardId, { closed: true });
    await dispatch("fetchCards", listId);
  },

  async handleCardDelete({ dispatch }, { cardId, listId }) {
    await trelloService.deleteTrelloCard(cardId);
    await dispatch("fetchCards", listId);
  },

  async handleCardAttachment({ dispatch, commit, state }, { cardId, listId }) {
    try {
      const updatedCard = await trelloService.getCard(cardId);
      const currentCards = [...(state.cards[listId] || [])];
      const cardIndex = currentCards.findIndex((c) => c.id === cardId);

      if (cardIndex !== -1) {
        currentCards[cardIndex] = updatedCard;
        commit("SET_CARDS", { listId, cards: currentCards });
        return updatedCard;
      } else {
        await dispatch("fetchCards", listId);
        return updatedCard;
      }
    } catch (error) {
      console.error("Error handling attachment:", error);
      commit("SET_ERROR", error.message);
      await dispatch("fetchCards", listId);
      throw error;
    }
  },

  async handleCardComment({ dispatch }, { cardId, listId }) {
    await dispatch("fetchCards", listId);
  },

  async refreshAffectedLists({ dispatch }, { oldListId, newListId }) {
    if (oldListId) await dispatch("fetchCards", oldListId);
    if (newListId && newListId !== oldListId)
      await dispatch("fetchCards", newListId);
  },

  stopSync({ state, commit }) {
    if (state.syncInterval) {
      clearInterval(state.syncInterval);
      commit("SET_SYNC_INTERVAL", null);
    }
  },

  async fetchBoardMembers({ commit }, boardId) {
    try {
      commit("SET_LOADING", true);
      const members = await trelloService.getBoardMembers(boardId);
      commit("SET_MEMBERS", members);
    } catch (error) {
      console.error("Error fetching board members:", error);
      commit("SET_ERROR", error.message);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async createChecklistWithItems({ commit }, { cardId, name, items }) {
    try {
      commit("SET_LOADING", true);
      return await trelloService.createChecklistWithItems(cardId, name, items);
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async deleteChecklist({ commit }, { cardId, checklistId }) {
    try {
      commit("SET_LOADING", true);
      return await trelloService.deleteChecklist(cardId, checklistId);
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async updateCheckItem(
    { commit },
    { cardId, checklistId, checkItemId, state }
  ) {
    try {
      commit("SET_LOADING", true);
      return await trelloService.updateCheckItem(
        cardId,
        checklistId,
        checkItemId,
        state
      );
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async createCheckItem({ commit }, { cardId, checklistId, name }) {
    try {
      commit("SET_LOADING", true);
      return await trelloService.createCheckItem(cardId, checklistId, name);
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async addComment({ commit }, { cardId, text }) {
    try {
      commit("SET_LOADING", true);
      return await trelloService.addComment(cardId, text);
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async fetchCardLocation({ commit }, { cardId }) {
    try {
      commit("SET_LOADING", true);
      const response = await fetch(
        `https://api.trello.com/1/cards/${cardId}/location?key=${process.env.VUE_APP_TRELLO_API_KEY}&token=${process.env.VUE_APP_TRELLO_TOKEN}`
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch card location: ${response.statusText}`
        );
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching card location:", error);
      commit("SET_ERROR", error.message);
      return null;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async fetchCardActions({ commit }, { cardId }) {
    try {
      commit("SET_LOADING", true);
      const actions = await trelloService.getCardActions(cardId);
      return actions;
    } catch (error) {
      console.error("Error fetching card actions:", error);
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async updateCardLocation({ commit }, { cardId, address }) {
    try {
      commit("SET_LOADING", true);
      // Update the card's location directly
      await trelloService.updateCardLocation(cardId, address);

      // Optionally refresh the card data to get the updated location
      const updatedCard = await trelloService.getCard(cardId);
      return updatedCard;
    } catch (error) {
      console.error("Error updating card location:", error);
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getCardLocation({ commit }, { cardId }) {
    try {
      commit("SET_LOADING", true);
      const address = await trelloService.getCardLocation(cardId);
      return address;
    } catch (error) {
      console.error("Error fetching card location:", error);
      commit("SET_ERROR", error.message);
      return null;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async startActivityPolling({ commit, dispatch, state }, { cardId }) {
    // Store the polling interval in the state
    if (state.activityPollingInterval) {
      clearInterval(state.activityPollingInterval);
    }

    // Initial fetch
    let lastFetchTime = new Date();
    await dispatch("fetchCardActions", { cardId });

    // Set up polling
    const pollingInterval = setInterval(async () => {
      try {
        const newActions = await trelloService.getCardActionsAfterDate(
          cardId,
          lastFetchTime
        );
        if (newActions && newActions.length > 0) {
          // Merge new actions with existing ones
          await dispatch("fetchCardActions", { cardId });
        }
        lastFetchTime = new Date();
      } catch (error) {
        console.error("Error polling card actions:", error);
      }
    }, 10000); // Poll every 10 seconds

    // Store the interval ID
    commit("SET_ACTIVITY_POLLING_INTERVAL", pollingInterval);
  },

  stopActivityPolling({ state, commit }) {
    if (state.activityPollingInterval) {
      clearInterval(state.activityPollingInterval);
      commit("SET_ACTIVITY_POLLING_INTERVAL", null);
    }
  },

  async createBoard({ commit }, boardData) {
    try {
      commit("SET_LOADING", true);
      const board = await trelloService.createBoard(boardData);
      return board;
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async createList({ commit }, listData) {
    try {
      commit("SET_LOADING", true);
      const list = await trelloService.createList(listData);
      return list;
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async createCards({ commit }, { listId, cards }) {
    try {
      commit("SET_LOADING", true);
      const result = await trelloService.createCards(listId, cards);

      if (result.failed.length > 0) {
        commit(
          "SET_ERROR",
          `Failed to create ${result.failed.length} cards. Check console for details.`
        );
      }

      return result.success;
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async getOrganizations({ commit }) {
    try {
      commit("SET_LOADING", true);
      const organizations = await trelloService.getOrganizations();
      return organizations;
    } catch (error) {
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async fetchCustomFields({ commit }, { boardId }) {
    try {
      commit("SET_LOADING", true);
      const customFields = await trelloService.fetchCustomFields(boardId);
      return customFields;
    } catch (error) {
      console.error("Error fetching custom fields:", error);
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async createCustomField({ commit }, { boardId, name, type, pos }) {
    try {
      commit("SET_LOADING", true);
      const customField = await trelloService.createCustomField(boardId, {
        name,
        type,
        pos,
      });
      return customField;
    } catch (error) {
      console.error("Error creating custom field:", error);
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async updateCustomFieldValue({ commit }, { cardId, customFieldId, value }) {
    try {
      commit("SET_LOADING", true);
      await trelloService.updateCustomFieldValue(cardId, customFieldId, value);
    } catch (error) {
      console.error("Error updating custom field value:", error);
      commit("SET_ERROR", error.message);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

const getters = {
  getBoardData: (state) => state.board,
  getLists: (state) => state.lists,
  getCardsByList: (state) => (listId) => state.cards[listId] || [],
  isLoading: (state) => state.loading,
  getError: (state) => state.error,
  getLastSyncTime: (state) => state.lastSyncTime,
  isSyncing: (state) => state.loading,
  getListColor: (state) => (listId) => {
    const list = state.lists.find((l) => l.id === listId);
    return list?.color ? TRELLO_COLORS[list.color] : null;
  },
  getBoardMembers: (state) => state.members,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
