<template>
  <v-menu
    offset-y
    bottom
    :close-on-content-click="false"
    ref="menu"
    transition="scale-transition"
    min-width="auto"
    min-height="auto"
    v-model="date.menu.value"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="label"
        :color="$vuetify.theme.themes.dark.primary"
        v-on="on"
        v-bind="attrs"
        :background-color="enabled && !eventDateSet ? 'yellow lighten-3' : ''"
        :hint="formattedFrequency"
        persistent-hint
        :value="formattedEventDate"
        readonly
        :disabled="!enabled"
        :hide-details="!date.menu.preset"
      >
      </v-text-field>
    </template>
    <v-date-picker
      :color="$vuetify.theme.themes.dark.primary"
      v-model="eventDates"
      multiple
      scrollable
      :events="suggestedDates"
      event-color="blue lighten-2"
      :picker-date.sync="date.pickerDate"
      :disabled="endDate.menu.value"
    >
      <template v-slot:default>
        <v-expand-transition>
          <div
            class="d-flex align-center flex-column px-3"
            style="width: 100%; gap: 6px"
          >
            <div class="d-flex justify-space-between" style="width: 100%">
              <v-btn
                color="blue white--text"
                class="rounded-pill"
                text
                small
                @click="handleEventDateToday"
                :disabled="pickerDateIsCurrentMonth"
              >
                <div class="d-flex align-center" style="gap: 6px">
                  <span class="font-weight-medium text-caption text-center"
                    >Today</span
                  >
                </div>
              </v-btn>
              <div class="d-flex" style="gap: 2px">
                <v-btn
                  color="black white--text"
                  class="rounded-pill"
                  icon
                  small
                  @click="handleEventDateUndo"
                  :disabled="!eventDates?.length"
                >
                  <div class="d-flex align-center" style="gap: 6px">
                    <v-icon size="16">mdi-undo</v-icon>
                  </div>
                </v-btn>
                <v-btn
                  color="red lighten-2 white--text "
                  class="rounded-pill"
                  icon
                  small
                  @click="handleEventDateClear"
                  :disabled="!eventDates?.length"
                >
                  <div class="d-flex align-center" style="gap: 6px">
                    <v-icon size="16">mdi-trash-can</v-icon>
                  </div>
                </v-btn>
              </div>
            </div>
            <v-expand-transition>
              <span
                :class="{
                  activeRotate: date.menu.expanded,
                }"
                class="d-flex align-center justify-center"
                style="cursor: pointer; gap: 4px"
                @click="date.menu.expanded = !date.menu.expanded"
                v-if="date.value?.length"
              >
                <span class="text--secondary text-caption font-weight-light"
                  >{{ date.menu.expanded ? "Hide" : "Show" }} Options</span
                >
                <v-icon size="20">mdi-chevron-down</v-icon>
              </span>
            </v-expand-transition>
            <v-expand-transition>
              <v-container
                fluid
                v-if="date.menu.expanded"
                class="pa-0 my-2 d-flex flex-column"
                style="row-gap: 12px"
              >
                <v-row
                  no-gutters
                  class="d-flex"
                  style="justify-content: space-evenly; row-gap: 6px"
                >
                  <v-col cols="6" class="d-flex align-center justify-center">
                    <span
                      class="text-body-2 font-weight-medium text--secondary d-flex justify-center align-center"
                      >Frequency</span
                    >
                  </v-col>
                  <v-col cols="6" class="d-flex justify-end">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs, value }">
                        <div
                          class="d-flex"
                          style="gap: 4px"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span
                            :class="
                              date.menu.preset
                                ? 'text--primary'
                                : 'text--disabled'
                            "
                            class="text-body-2 d-flex justify-center text-end align-center"
                            >{{
                              date.menu.preset?.length
                                ? datePickerOptions.find(
                                    (item) => item.value === date.menu.preset
                                  ).text
                                : "Not Set"
                            }}</span
                          >
                          <v-icon
                            v-if="date.menu.preset"
                            size="14"
                            @click.stop="
                              date.menu.preset = null;
                              eventEndDate = null;
                              handlePresetSelect();
                            "
                            >mdi-close-circle</v-icon
                          >
                          <v-icon
                            size="20"
                            :class="{
                              activeRotate: value,
                            }"
                            >mdi-menu-down</v-icon
                          >
                        </div>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="(item, index) in datePickerOptions"
                          :key="index"
                          :value="item.value"
                          @click="
                            date.menu.preset = item.value;
                            handlePresetSelect();
                          "
                        >
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-slide-y-transition
                  group
                  hide-on-leave
                  tag="div"
                  class="container container--fluid pa-0 d-flex flex-column"
                  style="row-gap: 12px"
                >
                  <v-row
                    v-if="date.menu.preset"
                    key="presetSelect"
                    no-gutters
                    class="d-flex"
                    style="justify-content: space-evenly; row-gap: 6px"
                  >
                    <v-col
                      cols="6"
                      class="d-flex align-center justify-center"
                      id="endDatePicker"
                    >
                      <span
                        class="text-body-2 font-weight-medium text--secondary d-flex justify-center align-center"
                        >End Date</span
                      >
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end">
                      <v-menu
                        offset-y
                        :offset-x="isMobileDevice"
                        :left="isMobileDevice"
                        :close-on-content-click="false"
                        ref="menu"
                        transition="scale-transition"
                        min-width="auto"
                        v-model="endDate.menu.value"
                        :disabled="!date.menu.preset"
                        content-class="rounded-xl"
                      >
                        <template v-slot:activator="{ on, attrs, value }">
                          <div
                            class="d-flex"
                            style="gap: 4px"
                            v-bind="attrs"
                            v-on="date.menu.preset ? on : ''"
                          >
                            <span
                              :class="
                                date.menu.preset && eventEndDate?.length
                                  ? 'text--primary'
                                  : date.menu.preset && !eventEndDate?.length
                                  ? 'red--text font-weight-medium'
                                  : 'text--disabled'
                              "
                              class="text-body-2 d-flex justify-center align-center"
                              >{{
                                eventEndDate?.length ? eventEndDate : "Not Set"
                              }}</span
                            >
                            <v-icon
                              v-if="eventEndDate?.length"
                              size="14"
                              @click.stop="eventEndDate = null"
                              >mdi-close-circle</v-icon
                            >
                            <v-icon
                              v-if="date.menu.preset && !eventEndDate?.length"
                              size="14"
                              color="red"
                              >mdi-alert-circle</v-icon
                            >
                            <v-icon
                              size="20"
                              :class="{
                                activeRotate: value,
                              }"
                              >mdi-menu-down</v-icon
                            >
                          </div>
                        </template>
                        <v-date-picker
                          :color="$vuetify.theme.themes.dark.primary"
                          no-title
                          scrollable
                          v-model="endDate.value"
                          :events="suggestedDates"
                          event-color="blue lighten-2"
                          :min="
                            date.value?.length
                              ? $options.filters.moment(
                                  date.value[0],
                                  'YYYY-MM-DD'
                                )
                              : undefined
                          "
                          @change="updateSelectedDates"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="date.menu.preset"
                    key="allowWeekendsToggle"
                    no-gutters
                    class="d-flex"
                    style="justify-content: space-evenly; row-gap: 6px"
                  >
                    <v-col cols="6" class="d-flex align-center justify-center">
                      <span
                        class="text-body-2 font-weight-medium text--secondary d-flex text-center justify-center align-center"
                        >Allow Weekends</span
                      >
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end align-center">
                      <v-switch
                        hide-details
                        v-model="date.menu.allowWeekends"
                        inset
                        :disabled="endDate.menu.preset"
                        class="mt-0 pt-0"
                        @change="
                          date.menu.moveToNextWeekday = false;
                          updateSelectedDates();
                        "
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row
                    key="nextWeekdayToggle"
                    v-if="!date.menu.allowWeekends && date.menu.preset"
                    no-gutters
                    class="d-flex"
                    style="justify-content: space-evenly; row-gap: 6px"
                  >
                    <v-col cols="6" class="d-flex align-center justify-end">
                      <span
                        class="text-body-2 font-weight-medium text--secondary d-flex text-center justify-center align-center"
                        >Move to Next Weekday</span
                      >
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end align-center">
                      <v-switch
                        hide-details
                        v-model="date.menu.moveToNextWeekday"
                        inset
                        :disabled="endDate.menu.preset"
                        class="mt-0 pt-0"
                        @change="updateSelectedDates"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-slide-y-transition>
              </v-container>
            </v-expand-transition>
          </div>
        </v-expand-transition>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script>
// Libraries
import { mapGetters } from "vuex";
import moment from "moment-timezone";
// Components

export default {
  name: "RecurringDatePicker",
  props: {
    enabled: {
      type: Boolean,
      default: false,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: {
      overall: false,
    },
    date: {
      menu: {
        value: false,
        preset: null,
        expanded: false,
        pickerDate: new Date(),
        allowWeekends: false,
        moveToNextWeekday: true,
      },
      value: [],
    },
    endDate: {
      menu: {
        value: false,
      },
      value: null,
    },
  }),
  created() {
    this.setup();
  },
  methods: {
    /* API Calls */
    // GET
    // POST
    /* Main */
    async setup() {
      this.loading.overall = true;
      this.loading.overall = false;
    },
    updateSelectedDates() {
      let duration = this.datePickerPreset?.duration ?? null;

      let endDate = moment(this.endDate.value);
      let startDate = moment(this.date.value[0]).clone();

      let results = [];
      const Sunday = 0;
      const Saturday = 6;
      let weekend = [Saturday, Sunday];

      let checkWeekend = (date) => {
        if (this.date.menu.allowWeekends) {
          return true;
        } else {
          return !weekend.includes(date.day());
        }
      };

      let tempArray = [];
      let tempAppendArray = [];
      let addToAppendArray = false;
      let slicedArray = this.date.value.slice();
      for (let index = 0; index < slicedArray.length; index++) {
        const element = slicedArray[index];
        if (typeof element === "object") {
          addToAppendArray = true;
          continue;
        } else if (addToAppendArray) {
          tempAppendArray.push(element);
          continue;
        }
        tempArray.push(element);
      }

      if (!this.endDate.value?.length || !this.date.value?.length) {
        this.date.value = [...tempArray];
        if (!this.date.value?.length) {
          this.date.menu.preset = null;
          this.endDate.value = null;
        }
        return;
      }

      let allowedDates = [...tempArray];

      if (tempArray.length > 1) {
        tempArray.forEach((date) => {
          if (typeof date === "string") {
            let flattenedResults = this.eventDates;
            startDate = moment(date).clone();
            startDate.add(duration);
            while (startDate.isSameOrBefore(endDate, "day")) {
              let tempStartDate = null;
              let formattedDate = startDate.format("YYYY-MM-DD");
              if (checkWeekend(startDate)) {
                allowedDates.push(formattedDate);
              } else if (this.date.menu.moveToNextWeekday) {
                tempStartDate = startDate.clone();
                while (tempStartDate.isSameOrBefore(endDate, "day")) {
                  tempStartDate.add(1, "day");
                  if (checkWeekend(tempStartDate)) {
                    formattedDate = tempStartDate.format("YYYY-MM-DD");
                    allowedDates.push(formattedDate);
                    break;
                  }
                }
              }
              if (
                !flattenedResults.find((date) => date === formattedDate) &&
                !results.find((date) => date === formattedDate) &&
                checkWeekend(tempStartDate ?? startDate)
              ) {
                results.push(formattedDate);
              }
              startDate.add(duration);
            }
            allowedDates = allowedDates.concat(tempAppendArray);
          }
        });
      } else {
        while (startDate.isSameOrBefore(endDate, "day")) {
          let tempStartDate = null;
          let flattenedResults = this.date.value.flat();
          let formattedDate = startDate.format("YYYY-MM-DD");
          if (checkWeekend(startDate)) {
            allowedDates.push(formattedDate);
          } else if (this.date.menu.moveToNextWeekday) {
            tempStartDate = startDate.clone();
            while (tempStartDate.isSameOrBefore(endDate, "day")) {
              tempStartDate.add(1, "day");
              if (checkWeekend(tempStartDate)) {
                formattedDate = tempStartDate.format("YYYY-MM-DD");
                allowedDates.push(formattedDate);
                break;
              }
            }
          }
          if (
            !flattenedResults.find((date) => date === formattedDate) &&
            !results.find((date) => date === formattedDate) &&
            checkWeekend(tempStartDate ?? startDate)
          ) {
            results.push(formattedDate);
          }
          allowedDates = allowedDates.concat(tempAppendArray);
          startDate.add(duration);
        }
      }
      let resultsArray = this.date.value;
      if (results?.length) {
        resultsArray.push(results);
      }
      this.date.value = resultsArray
        .map((date) => {
          if (typeof date === "object") {
            return date.filter(
              (item, index) =>
                allowedDates.includes(item) &&
                resultsArray
                  .slice()
                  .flat()
                  .findIndex((val) => val === item) !== index
            );
          } else {
            return date;
          }
        })
        .filter((date, index) => {
          if (typeof date === "object") {
            return date.length > 0;
          } else {
            return allowedDates.includes(date);
          }
        });
    },
    async handlePresetSelect() {
      if (this.$vuetify.breakpoint.xsOnly) {
        await this.delay(100);
        this.$nextTick(() => {
          document.getElementById("endDatePicker").scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        });
      }
      this.updateSelectedDates();
    },
    handleEventDateUndo() {
      this.date.value.splice(-1, 1);
      if (!this.date.value?.length) {
        this.date.menu.expanded = false;
        this.date.menu.preset = null;
        this.endDate.value = null;
      }
    },
    handleEventDateClear() {
      this.date.value.splice(0);
      this.date.menu.expanded = false;
      this.date.menu.preset = null;
      this.endDate.value = null;
    },
    handleEventDateToday() {
      this.date.pickerDate = moment().format("YYYY-MM");
    },
    /* Misc */
  },
  computed: {
    ...mapGetters(["getAppName", "getUser"]),
    eventDateSet() {
      return this.date.value?.length ? true : false;
    },
    formattedEventDate() {
      if (this.eventDateSet) {
        let dateLength = this.eventDates.length;
        let start = moment(this.eventDates[0]).format("MM/DD/YYYY");
        if (dateLength > 1) {
          if (this.date.menu.preset) {
            let end = moment(this.eventDates[dateLength - 1]).format(
              "MM/DD/YYYY"
            );
            return `${start} - ${end}`;
          } else {
            let mappedDates = this.eventDates
              .map((date) => moment(date).format("MM/DD/YYYY"))
              .sort((a, b) => (moment(a).isBefore(b) ? -1 : 1));
            return mappedDates.join(", ");
          }
        } else {
          return start;
        }
      } else {
        return "";
      }
    },
    formattedFrequency() {
      return this.datePickerOptions.find(
        (option) => option.value === this.date.menu.preset
      )
        ? `Frequency: ${
            this.datePickerOptions.find(
              (option) => option.value === this.date.menu.preset
            )?.text ?? ""
          }`
        : "";
    },
    // Date Picker Options
    datePickerOptions() {
      let momentDuration = (frequency) => {
        let duration = null;
        switch (frequency) {
          case "daily":
            duration = moment.duration(1, "days");
            break;
          case "everyOtherDay":
            duration = moment.duration(2, "days");
            break;
          case "weekly":
            duration = moment.duration(1, "weeks");
            break;
          case "everyOtherWeek":
            duration = moment.duration(2, "weeks");
            break;
          case "monthly":
            duration = moment.duration(1, "months");
            break;
          case "everyOtherMonth":
            duration = moment.duration(2, "months");
            break;
          case "quarterly":
            duration = moment.duration(3, "months");
            break;
          case "semiannually":
            duration = moment.duration(6, "months");
            break;
          case "annually":
            duration = moment.duration(1, "years");
            break;
          default:
            duration = null; // Handle unknown values
            break;
        }
        return duration;
      };
      return [
        {
          text: "Daily",
          value: "daily",
          disabled: this.date.value?.length > 1 && this.date.preset !== "daily",
          duration: momentDuration("daily"),
        },
        {
          text: "Every Other Day",
          value: "everyOtherDay",
          disabled:
            this.date.value?.length > 1 && this.date.preset !== "everyOtherDay",
          duration: momentDuration("everyOtherDay"),
        },
        { text: "Weekly", value: "weekly", duration: momentDuration("weekly") },
        {
          text: "Every Other Week",
          value: "everyOtherWeek",
          duration: momentDuration("everyOtherWeek"),
        },
        {
          text: "Monthly",
          value: "monthly",
          duration: momentDuration("monthly"),
        },
        {
          text: "Every Other Month",
          value: "everyOtherMonth",
          duration: momentDuration("everyOtherMonth"),
        },
        {
          text: "Quarterly",
          value: "quarterly",
          duration: momentDuration("quarterly"),
        },
        {
          text: "Semiannually",
          value: "semiannually",
          duration: momentDuration("semiannually"),
        },
        {
          text: "Annually",
          value: "annually",
          duration: momentDuration("annually"),
        },
      ];
    },
    datePickerPreset() {
      return this.datePickerOptions.find(
        (item) => item.value === this.date.menu.preset
      );
    },
    eventEndDate: {
      get: function () {
        return this.endDate.value
          ? this.$options.filters.moment(this.endDate.value, "M/D/YY")
          : "";
      },
      set: function (value) {
        this.endDate.value = value;
        this.updateSelectedDates();
      },
    },
    eventDates: {
      get: function () {
        return this.date.value
          ? this.date.value
              .slice()
              .flat()
              .sort((a, b) => {
                let aDate = moment(a);
                let bDate = moment(b);
                return aDate.isBefore(bDate, "day") ? -1 : 1;
              })
          : this.date.value;
      },
      set: function (value) {
        if (value) {
          if (!this.date.value?.length) {
            this.date.value = [];
          }
          value.forEach((item) => {
            if (!this.eventDates?.includes(item)) {
              this.date.value.push(item);
            }
          });
          this.date.value = this.date.value.filter(
            (item) => value.includes(item) || typeof item === "object"
          );
        } else {
          this.date.value = value;
        }
        this.updateSelectedDates();
        this.$emit("input", this.date.value);
      },
    },
    pickerDateIsCurrentMonth() {
      return moment(this.date.pickerDate).isSame(moment(this.today), "month");
    },
    suggestedDates() {
      if (!this.date.value?.length || !this.date.menu.preset) {
        return [];
      }

      let duration = this.datePickerPreset?.duration ?? null;

      let startDate = moment(this.date.value[0]).clone();
      let endDate = moment(this.endDate.value ?? startDate.add(5, "years"));

      let results = [];

      let tempArray = [];
      let tempAppendArray = [];
      let addToAppendArray = false;
      let slicedArray = this.date.value.slice();
      for (let index = 0; index < slicedArray.length; index++) {
        const element = slicedArray[index];
        if (typeof element === "object") {
          addToAppendArray = true;
          continue;
        } else if (addToAppendArray) {
          tempAppendArray.push(element);
          continue;
        }
        tempArray.push(element);
      }

      let allowedDates = [...tempArray];

      if (tempArray.length > 1) {
        tempArray.forEach((date) => {
          if (typeof date === "string") {
            let flattenedResults = this.eventDates;
            startDate = moment(date).clone();
            startDate.add(duration);
            while (startDate.isSameOrBefore(endDate, "day")) {
              let formattedDate = startDate.format("YYYY-MM-DD");
              allowedDates.push(formattedDate);
              if (!flattenedResults.find((date) => date === formattedDate)) {
                results.push(formattedDate);
              }
              startDate.add(duration);
            }
            allowedDates = allowedDates.concat(tempAppendArray);
          }
        });
      } else {
        while (startDate.isSameOrBefore(endDate, "day")) {
          let flattenedResults = this.date.value.flat();
          let formattedDate = startDate.format("YYYY-MM-DD");
          if (!flattenedResults.find((date) => date === formattedDate)) {
            results.push(formattedDate);
          }
          allowedDates = allowedDates.concat(tempAppendArray);
          startDate.add(duration);
        }
      }
      let resultsArray = this.date.value.slice();
      if (results?.length) {
        resultsArray.push(results);
      }
      return resultsArray
        .map((date) => {
          if (typeof date === "object") {
            return date.filter(
              (item, index) =>
                allowedDates.includes(item) &&
                resultsArray
                  .slice()
                  .flat()
                  .findIndex((val) => val === item) !== index
            );
          } else {
            return date;
          }
        })
        .filter((date) => {
          if (typeof date === "object") {
            return date.length > 0;
          } else {
            return allowedDates.includes(date);
          }
        })
        .flat();
    },
  },
};
</script>

<style scoped>
.activeRotate .v-icon {
  transform: rotate(180deg);
}
.activeRotate::before {
  transform: rotate(180deg);
}
</style>
