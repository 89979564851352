import Vue from "vue";
import Vuex from "vuex";
import trello from "./modules/trello";

// Modules
import Phase0_Admin from "./phase/0_admin/phase0_admin";
import Phase1_Sourcing from "./phase/1_sourcing/phase1_sourcing";
import Phase2_Manufacturing from "./phase/2_manufacturing/phase2_manufacturing";
import Phase3_Sales from "./phase/3_sales/phase3_sales";
import Phase4_Support from "./phase/4_support/phase4_support";
import Misc from "./Misc";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Phase0_Admin,
    Phase1_Sourcing,
    Phase2_Manufacturing,
    Phase3_Sales,
    Phase4_Support,
    Misc,
    trello,
  },
});
