const state = {
    specialTypes: [
        { text: "Price Discount", desc: "Set the special pricing per category, savings will be shown during order submisison", value: "price" },
        // { text: "Additional Product", desc: "", value: "additionalProduct" },
      ],
};

const actions = {};

const mutations = {};

const getters = {
    getSpecialTypes(state) {
        return state.specialTypes;
    }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
