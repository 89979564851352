// Trello API configuration
const TRELLO_KEY = process.env.VUE_APP_TRELLO_API_KEY;
const TRELLO_TOKEN = process.env.VUE_APP_TRELLO_TOKEN;
const BASE_URL = "https://api.trello.com/1";

// Add these constants at the top of the file
const RATE_LIMIT_DELAY = 100; // 100ms between requests
const RATE_LIMIT_WINDOW = 10000; // 10 second window
const MAX_REQUESTS_PER_WINDOW = 100; // Trello's limit is 100 requests per 10 seconds
const BATCH_SIZE = 10; // Process cards in smaller batches
const MAX_RETRIES = 3;

// Helper function to build URLs with auth params
const buildUrl = (endpoint, queryParams = {}) => {
  if (!TRELLO_KEY || !TRELLO_TOKEN) {
    throw new Error(
      "Trello API key and token must be configured in environment variables"
    );
  }

  const baseParams = `key=${TRELLO_KEY}&token=${TRELLO_TOKEN}`;
  const additionalParams = Object.entries(queryParams)
    .map(([key, value]) => `&${key}=${value}`)
    .join("");

  return `${BASE_URL}${endpoint}?${baseParams}${additionalParams}`;
};

// Helper function to handle API responses
const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.text();
    throw new Error(`Trello API Error: ${error}`);
  }
  return response.json();
};

// Add this helper function for delay
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Add this queue manager class
class RequestQueue {
  constructor() {
    this.queue = [];
    this.processing = false;
    this.requestsInWindow = 0;
    this.windowStart = Date.now();
  }

  async add(fn) {
    return new Promise((resolve, reject) => {
      this.queue.push({ fn, resolve, reject });
      if (!this.processing) {
        this.process();
      }
    });
  }

  async process() {
    if (this.queue.length === 0) {
      this.processing = false;
      return;
    }

    this.processing = true;
    const now = Date.now();

    // Reset window if needed
    if (now - this.windowStart >= RATE_LIMIT_WINDOW) {
      this.requestsInWindow = 0;
      this.windowStart = now;
    }

    // Check if we need to wait for the next window
    if (this.requestsInWindow >= MAX_REQUESTS_PER_WINDOW) {
      const waitTime = RATE_LIMIT_WINDOW - (now - this.windowStart);
      await delay(waitTime);
      this.requestsInWindow = 0;
      this.windowStart = Date.now();
    }

    const { fn, resolve, reject } = this.queue.shift();

    try {
      // Add delay between requests
      await delay(RATE_LIMIT_DELAY);
      const result = await fn();
      this.requestsInWindow++;
      resolve(result);
    } catch (error) {
      reject(error);
    }

    // Process next item in queue
    this.process();
  }
}

// Create a single instance of the queue
const requestQueue = new RequestQueue();

// Add this retry helper function at the top with other helpers
const retryWithBackoff = async (fn, retries = MAX_RETRIES) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await fn();
    } catch (error) {
      if (error.message.includes("429") && i < retries - 1) {
        // Exponential backoff: wait longer between each retry
        const waitTime = Math.pow(2, i) * 1000 + Math.random() * 1000;
        await delay(waitTime);
        continue;
      }
      throw error;
    }
  }
};

// Add new batch helper function
const createBatchActions = (listId, cards) => {
  return cards.map((card) => ({
    method: "POST",
    path: "/cards",
    body: {
      idList: listId,
      name: card.name,
      desc: card.desc,
      address: card.address,
      coordinates: card.coordinates,
    },
  }));
};

export default {
  // Get board details
  async getBoard(boardId) {
    if (!boardId) {
      throw new Error("Board ID is required");
    }
    const response = await fetch(buildUrl(`/boards/${boardId}`));
    return handleResponse(response);
  },

  // Get lists in a board
  async getLists(boardId) {
    if (!boardId) {
      throw new Error("Board ID is required");
    }

    try {
      const response = await fetch(buildUrl(`/boards/${boardId}/lists`));

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch lists: ${errorText}`);
      }

      const data = await response.json();

      if (!Array.isArray(data)) {
        throw new Error("Invalid response format from Trello API");
      }

      return data;
    } catch (error) {
      console.error("Error fetching lists:", error);
      throw error;
    }
  },

  // Get cards in a list with all details
  async getCards(listId) {
    if (!listId) {
      throw new Error("List ID is required");
    }
    const response = await fetch(
      buildUrl(`/lists/${listId}/cards`, {
        attachments: "true",
        attachment_fields: "all",
        members: "true",
        member_fields: "all",
        checklists: "all",
        checkItemStates: "true",
        customFieldItems: "true",
      })
    );
    return handleResponse(response);
  },

  // Get a single card with all details
  async getCard(cardId) {
    if (!cardId) {
      throw new Error("Card ID is required");
    }
    const response = await fetch(
      buildUrl(`/cards/${cardId}`, {
        attachments: "true",
        attachment_fields: "all",
        members: "true",
        member_fields: "all",
        checklists: "all",
        checkItemStates: "true",
      })
    );
    return handleResponse(response);
  },

  // Create a new card
  async createCard(listId, cardData) {
    if (!listId) {
      throw new Error("List ID is required");
    }

    return requestQueue.add(async () => {
      return retryWithBackoff(async () => {
        const response = await fetch(buildUrl(`/cards`), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idList: listId,
            name: cardData.name,
            desc: cardData.desc,
            address: cardData.address,
            coordinates: cardData.coordinates,
          }),
        });

        if (response.status === 429) {
          throw new Error("429 Rate limit exceeded");
        }

        return handleResponse(response);
      });
    });
  },

  // Update a card
  async updateCard(cardId, updates) {
    const response = await fetch(buildUrl(`/cards/${cardId}`), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updates),
    });
    return await response.json();
  },

  // Attach a file to a card
  async attachFileToCard(cardId, formData) {
    const response = await fetch(buildUrl(`/cards/${cardId}/attachments`), {
      method: "POST",
      body: formData,
    });
    return handleResponse(response);
  },

  // Update a card's list
  async updateCardList(cardId, newListId) {
    if (!cardId || !newListId) {
      throw new Error("Card ID and new List ID are required");
    }
    const response = await fetch(buildUrl(`/cards/${cardId}`), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idList: newListId,
      }),
    });
    return handleResponse(response);
  },

  // Update a card's position
  async updateCardPosition(cardId, newPosition) {
    if (!cardId) {
      throw new Error("Card ID is required");
    }
    const response = await fetch(buildUrl(`/cards/${cardId}`), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pos: newPosition,
      }),
    });
    return handleResponse(response);
  },

  // Update a Trello card
  async updateTrelloCard(cardId, updateData) {
    if (!cardId) {
      throw new Error("Card ID is required");
    }

    const response = await fetch(buildUrl(`/cards/${cardId}`), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateData),
    });
    return handleResponse(response);
  },

  // Delete a Trello card
  async deleteTrelloCard(cardId) {
    if (!cardId) {
      throw new Error("Card ID is required");
    }

    const response = await fetch(buildUrl(`/cards/${cardId}`), {
      method: "DELETE",
    });
    return handleResponse(response);
  },

  // Get board members
  async getBoardMembers(boardId) {
    if (!boardId) {
      throw new Error("Board ID is required");
    }
    const response = await fetch(
      buildUrl(`/boards/${boardId}/members`, {
        fields: "all",
      })
    );
    return handleResponse(response);
  },

  // Simplified version of createChecklist that doesn't require delays
  async createChecklistWithItems(cardId, name, items) {
    // Create checklist
    const checklist = await fetch(buildUrl(`/cards/${cardId}/checklists`), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name }),
    });

    const checklistData = await handleResponse(checklist);

    // Create checklist items sequentially to maintain order
    if (items && items.length > 0) {
      try {
        // Use for...of to maintain order
        for (const item of items) {
          const response = await fetch(
            buildUrl(`/checklists/${checklistData.id}/checkItems`),
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ name: item }),
            }
          );
          await handleResponse(response);
        }
      } catch (error) {
        console.error("Error creating checklist items:", error);
        throw error;
      }
    } else {
      console.warn("No items provided for checklist");
    }

    return checklistData;
  },

  async deleteChecklist(cardId, checklistId) {
    const response = await fetch(
      buildUrl(`/cards/${cardId}/checklists/${checklistId}`),
      {
        method: "DELETE",
      }
    );
    return handleResponse(response);
  },

  async updateCheckItem(cardId, checklistId, checkItemId, state) {
    const response = await fetch(
      buildUrl(
        `/cards/${cardId}/checklist/${checklistId}/checkItem/${checkItemId}`
      ),
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ state }),
      }
    );
    return handleResponse(response);
  },

  async createCheckItem(cardId, checklistId, name) {
    const response = await fetch(
      buildUrl(`/cards/${cardId}/checklist/${checklistId}/checkItem`),
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name }),
      }
    );
    return handleResponse(response);
  },

  async addComment(cardId, text) {
    const response = await fetch(
      buildUrl(`/cards/${cardId}/actions/comments`),
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ text }),
      }
    );
    return handleResponse(response);
  },

  async updateCardLocation(cardId, address) {
    try {
      // First, geocode the address using Google Maps
      const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`;
      const geocodeResponse = await fetch(geocodingUrl);
      const geocodeData = await geocodeResponse.json();

      if (!geocodeData.results || geocodeData.results.length === 0) {
        throw new Error("Location not found");
      }

      const formattedAddress = geocodeData.results[0].formatted_address;
      const location = geocodeData.results[0].geometry.location;

      // Now update Trello with the coordinates and formatted address
      const response = await fetch(buildUrl(`/cards/${cardId}`), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          coordinates: {
            latitude: location.lat,
            longitude: location.lng,
          },
          address: formattedAddress,
          locationName: formattedAddress,
        }),
      });

      const result = await handleResponse(response);
      return result;
    } catch (error) {
      console.error("Error in updateCardLocation:", error);
      throw error;
    }
  },

  async getCardLocation(cardId) {
    try {
      const response = await fetch(
        buildUrl(`/cards/${cardId}`, {
          fields: "all",
        })
      );

      const card = await handleResponse(response);

      // Return both coordinates and address if they exist
      return {
        coordinates: card.coordinates || null,
        address: card.address || null,
        locationName: card.locationName || null,
      };
    } catch (error) {
      console.error("Error in getCardLocation:", error);
      throw error;
    }
  },

  // Helper method to get coordinates from address
  async getCoordinatesFromAddress(address) {
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`;
    const response = await fetch(geocodingUrl);
    const data = await response.json();

    if (data.results && data.results.length > 0) {
      const location = data.results[0].geometry.location;
      return {
        latitude: location.lat,
        longitude: location.lng,
        formattedAddress: data.results[0].formatted_address,
      };
    }
    throw new Error("Location not found");
  },

  // Update the getCardActions method in trelloService to get more detailed data
  async getCardActions(cardId) {
    const response = await fetch(
      buildUrl(`/cards/${cardId}/actions`, {
        filter: "all",
        limit: "1000",
        memberCreator: "true",
        memberCreator_fields: "fullName,username,avatarUrl",
        member: "true",
        member_fields: "fullName,username",
        display: "true",
        entities: "true",
        // Add fields to get attachment previews and other details
        fields:
          "id,idMemberCreator,data,date,type,memberCreator,member,display,entities",
      })
    );
    return handleResponse(response);
  },

  // Add a method to get actions after a specific date
  async getCardActionsAfterDate(cardId, dateTime) {
    const response = await fetch(
      buildUrl(`/cards/${cardId}/actions`, {
        filter: "all",
        since: dateTime.toISOString(),
        memberCreator: "true",
        memberCreator_fields: "fullName,username,avatarUrl",
        member: "true",
        member_fields: "fullName,username",
        display: "true",
        entities: "true",
      })
    );
    return handleResponse(response);
  },

  // Create a new board
  async createBoard(boardData) {
    if (!boardData.name) {
      throw new Error("Board name is required");
    }

    const url = buildUrl("/boards");

    const requestBody = {
      name: boardData.name,
      defaultLists: boardData.defaultLists || false,
      idOrganization: boardData.idOrganization,
      prefs: {
        backgroundColor: "#0079BF",
        permissionLevel: "org",
        visibility: "org",
      },
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    const data = await handleResponse(response);

    if (data.url) {
      window.open(data.url, "_blank");
    }

    return data;
  },

  // Get organizations (workspaces)
  async getOrganizations() {
    const response = await fetch(buildUrl("/members/me/organizations"));
    const data = await handleResponse(response);
    return data;
  },

  // Create a new list
  async createList(listData) {
    if (!listData.name || !listData.idBoard) {
      throw new Error("List name and board ID are required");
    }

    const url = buildUrl("/lists");

    const requestBody = {
      name: listData.name,
      idBoard: listData.idBoard,
      pos: listData.pos || "bottom",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to create list: ${errorText}`);
      }

      const data = await response.json();

      if (!data.id) {
        throw new Error("Created list does not have an ID");
      }

      return data;
    } catch (error) {
      console.error("Error in createList:", error);
      throw error;
    }
  },

  // Update createCards method to use batching
  async createCards(listId, cards) {
    if (!listId || !Array.isArray(cards)) {
      throw new Error("List ID and cards array are required");
    }

    const results = {
      success: [],
      failed: [],
    };

    // Process in chunks of 10 (Trello's batch API limit)
    for (let i = 0; i < cards.length; i += 10) {
      const chunk = cards.slice(i, i + 10);
      const batchActions = createBatchActions(listId, chunk);

      try {
        const response = await requestQueue.add(async () => {
          return retryWithBackoff(async () => {
            const res = await fetch(buildUrl("/batch"), {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ urls: batchActions }),
            });

            if (res.status === 429) {
              throw new Error("429 Rate limit exceeded");
            }

            return handleResponse(res);
          });
        });

        // Process batch results
        response.forEach((result, index) => {
          if (result.status === 200) {
            results.success.push(result.body);
          } else {
            results.failed.push({
              card: chunk[index],
              error: result.body,
            });
          }
        });

        // Add delay between batches
        await delay(RATE_LIMIT_DELAY);
      } catch (error) {
        console.error("Batch operation failed:", error);
        chunk.forEach((card) => {
          results.failed.push({
            card,
            error: error.message,
          });
        });
      }
    }

    if (results.failed.length > 0) {
      console.warn(
        `Failed to create ${results.failed.length} cards:`,
        results.failed
      );
    }

    return results;
  },

  // Fetch custom fields for a board
  async fetchCustomFields(boardId) {
    if (!boardId) {
      throw new Error("Board ID is required");
    }
    const response = await fetch(buildUrl(`/boards/${boardId}/customFields`));
    return handleResponse(response);
  },

  // Create a custom field
  async createCustomField(boardId, { name, type, pos }) {
    if (!boardId) {
      throw new Error("Board ID is required");
    }
    const response = await fetch(buildUrl(`/customFields`), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idModel: boardId,
        modelType: "board",
        name,
        type,
        pos,
      }),
    });
    return handleResponse(response);
  },

  // Update custom field value for a card
  async updateCustomFieldValue(cardId, customFieldId, value) {
    if (!cardId || !customFieldId) {
      throw new Error("Card ID and Custom Field ID are required");
    }
    const response = await fetch(
      buildUrl(`/cards/${cardId}/customField/${customFieldId}/item`),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: { date: value },
        }),
      }
    );
    return handleResponse(response);
  },
};
